<template>
  <div :class="{ white: backgroundw }" class="text-preview-wrp">
    <section class="container">
      <div class="categ_short_desc">
        <h1 class="categ-title">
          <skeleton-box v-if="!isCategLoad && categ" :min-width="20" :max-width="50" />
          <template v-else>{{ categ.name }}</template>
        </h1>
      </div>
    </section>

    <section itemprop="description" class="container short-description">
      <template v-if="isCategLoad">
        <read-more
          v-if="isMiddleDevice"
          :more-str="readMore"
          :text="msg"
          link="#"
          less-str="Citeste mai putin"
          :max-chars="150"
        ></read-more>
        <p v-else class="wysiwyg pb-0 text-preview" v-html="text_preview"></p>
      </template>
      <template v-else>
        <skeleton-box />
        <skeleton-box />
        <skeleton-box />
      </template>
    </section>
  </div>
</template>

<script>
import SkeletonBox from "@/components/blocks/SkeletonBox.vue";

export default {
  data() {
    return {
      // msg: 'Doresti un focar de semineu trainic la un pret avantajos? La PEFOC gasesti focarele cu un foarte bun raport calitate - pret de pe piata! Aceste focare de semineu sunt produse de incredere si de inalta calitate, pentru care oferim o garantie intre 5-10 ani. Verifica produsele de top si promotiile oferite de noi si alege cel mai bun focar semineu pentru tine! Cu toate ca exista posibilitatea de a nu avea o reprezentanta in orasul tau, totusi oferim transportul gratuit la domiciliu oriunde in Romania.'
    };
  },
  props: {
    backgroundw: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SkeletonBox,
  },

  computed: {
    categ() {
      return this.$store.getters["shop/category"];
    },

    text_preview() {
      return this.$store.getters["shop/text_preview"];
    },

    isCategLoad() {
      // console.log(this.$store.getters['shop/categoryIsLoad']);
      return this.$store.getters["shop/categoryIsLoad"];
    },

    isMiddleDevice() {
      return this.$mq === "sm" ? true : false;
    },

    readMore() {
      return "Citeste mai mult";
    },

    msg() {
      return this.categ.preview_text ? this.categ.preview_text : "";
    },
  },
};
</script>

<style scoped>
.categ-title {
  font-family: "Raleway SemiBold";
}

.text-preview >>> p {
  font-size: 14px;
}

.white {
  background: #fff;
}

.text-preview-wrp {
  display: flow-root;
}
.short-description {
  position: relative;
}

@media (max-width: 576px) {
  .text-preview-wrp {
    background: #fff;
  }
  .short-description {
    margin-bottom: 50px;
    font-family: 'Montserrat Medium';
    font-size: 12px;
  }
}
</style>
