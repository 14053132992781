<template>
  <div class="section products-mobile-filter w-100 position-fixed flex-column" id="products-mobile-filter">
      <ActiveFilterMobileBox @closeFilter="closeFilter" @closeOptions="closeOptions" :isFilterOpen="openFilter" />
      <AvailableFilterMobileBox @isFilterOpen="isFilterOpen" :closeOptions="openFilter" />
      <ResultsFilterMobileBox @click="closeFilter"/>
  </div>
</template>

<script>
import ActiveFilterMobileBox from "@/components/filter/products/ActiveFilterMobileBox";
import AvailableFilterMobileBox from "@/components/filter/products/AvailableFilterMobileBox";
import ResultsFilterMobileBox from "@/components/filter/products/ResultsFilterMobileBox";


export default {
  data() {
    return {
      openFilter: false,
    }
  },

  components: {
    ActiveFilterMobileBox,
    AvailableFilterMobileBox,
    ResultsFilterMobileBox,
  },
  name: 'ProductsFilter',
  emits: ['closeMobileFilter'], 
  computed: {

  },
  methods: {

    closeFilter() {
        this.$emit('closeMobileFilter');
        this.openFilter = false;
    },
    isFilterOpen(fOpen) {
      this.openFilter = fOpen;
    },
    closeOptions() {
      this.openFilter = false;
    }

  }
};
</script>

<style scoped>

.products-mobile-filter {
    z-index: 9999;
    top: 100px;
    height: calc(100% - 100px);
    display: flex;
    background-color: rgb(243, 243, 243);
}

@media (max-width: 576px) {

    .products-mobile-filter {
      top: 60px;
      height: calc(100% - 60px);
    }
    
}

</style>