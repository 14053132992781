<template>
  <div class="section filtersNavbar d-sm-block" :class="{ sticky: scrollNavbar }">
    <div class="container">
      <div class="filters filter-content filter-header">
        <CloseFilter v-if="windowWidth < 576" />
        <div class="filter-items filter-default filters-mobile" id="filter_blog">
          <div>
            <div class="row item">
              <!-- <div class="filter-name">
                <span class="filter-label d-none d-sm-block"
                  >Filtreaza:</span
                >
                <span class="filter-label d-block d-sm-none"
                  >Filtreaza:</span
                >
              </div> -->

              <div class="button-group js-radio-button-group" data-filter-group="categ">
                <div v-for="(categ, index) in categories" :key="index" class="filter-row">
                  <button
                    v-if="categ.slug !== $route.params.slug"
                    class="button"
                    :class="{ 'is-checked': isActive(categ.slug) }"
                    @click="closeFilter"
                  >
                    <router-link
                      :to="{ name: 'ProductList', params: { slug: categ.slug } }"
                    >
                      {{ categ.name }}
                    </router-link>
                  </button>

                  <button
                    v-else-if="index > 0"
                    class="button"
                    :class="{ 'is-checked': isActive(categ.slug) }"
                    @click="closeFilter"
                  >
                    <router-link
                      :to="{ name: 'ProductList', params: { slug: categ.slug } }"
                    >
                      {{ categ.name }}
                    </router-link>
                  </button>
                </div>
              </div>
            </div>

            <div class="products-count d-block d-sm-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import CloseFilter from "@/components/filter/CloseFilter.vue";

export default {
  name: "NavigationFilter",
  props: ["categories"],
  emits: ["onSelect"],
  data() {
    return {
      windowWidth: window.innerWidth,
      scrollNavbar: false,
    };
  },
  components: {
    CloseFilter,
  },
  computed: {
    // ...mapGetters('filter', ['dFilter']),
    // categs() {
    //   return this.$store.getters['blog/categories'];
    // },
  },

  methods: {
    selectedCat(categ) {
      this.$emit("onSelect", categ);
    },
    isActive(slug) {
      return this.$route.params.slug === slug;
    },
    handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > 180) {
        this.scrollNavbar = true;
      } else {
        this.scrollNavbar = false;
      }
    },
    closeFilter() {
      this.$store.dispatch("filter/actionImgFilter", true);
    },

    // categIsActive(categ_id = 0) {
    //   console.log(categ_id);
    // }
  },
  mounted() {
    // console.log(this.categories);
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.filter-items.filter-default .button > a:hover {
  text-decoration: none;
  background: #97040c;
  border-radius: 28px;
}

.sticky {
  position: fixed !important;
  right: 0px;
  z-index: 99;
  margin: 0;
  top: 0px;
  width: 100%;
}

@media (max-width: 768px) {
  .filtersNavbar {
    background-color: #000;
    position: absolute;
    z-index: 5;
    top: 60px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .sticky {
    top: 60px;
  }
}
</style>
