<template>
  <div class="container wrp-slogan">
    <div class="responsive-text">
      <div class="livrare-gratis product-livrare modele d-none d-md-block">
        <template v-if="slogan">
          <span class="livrare-gratis" :class="{ livrare: shipping }"></span>
          <span class="livrare-text" :class="{ livrare: !shipping }">
            {{ slogan }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SloganLivrare",
  props: {
    slogan: {
      type: String,
      default: "Livrare in toata Romania",
    },
    shipping: {
      type: Boolean,
    },
  },

  // mounted() {
  //   console.log(this.shipping);
  // }
};
</script>

<style>
@import "../../assets/css/slogan-livrare.css";
</style>
