<template>
  <section class="container-fliud breadcrumb-list">
    <nav class="container breadcrumb-container" aria-label="breadcrumb">
      <ol class="breadcrumb pl-0 pr-0">
        <li v-if="breadcrumbs.length < 3" class="breadcrumb-item" aria-current="page">
          <a @click="goto()" class="breadcrumb-link"> Produse </a>
        </li>
        <li
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="index"
          class="breadcrumb-item"
        >
          <router-link
            class="breadcrumb-link"
            :class="{
              currentCateg: index === breadcrumbs.length - 1 && isEmptyObj(product),
              lastItem: index === breadcrumbs.length - 1 && isEmptyObj(product),
            }"
            :to="{ name: 'ProductList', params: { slug: breadcrumb.slug } }"
            >{{ breadcrumb.name }}</router-link
          >
        </li>

        <li
          v-if="!isEmptyObj(product)"
          class="breadcrumb-item breadcrump-last-item lastItem"
        >
          <span class="breadcrumb-link currentCateg">{{ product.name }}</span>
        </li>
      </ol>
    </nav>
  </section>
</template>

<script>
import { generalMixin } from "@/mixins/generalMixin";

export default {
  name: "Breadcrumb",
  props: ["breadcrumbs"],
  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },
  },
  mixins: [generalMixin],
  methods: {
    goto() {
      if(this.$route.name == "Home") {
        var homepagecategs = document.getElementById("produse");
        homepagecategs.scrollIntoView({ behavior: "smooth", block: "center"});
      } else {
        document.cookie = "scrollToProducts=1";
        this.$router.push({
          path: '/',
        });
      }
    },
    isEmptyObj(obj) {
      return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
  },

  mounted() {
    // console.log(this.product);
    // console.log(this.product.category.breadcurmbs);
  },
};
</script>

<style scoped>
@import "../../assets/css/breadcrumb.css";

.breadcrumb-link {
  color: #000;
  cursor: pointer;
}

.breadcrumb-link:hover {
  color: #3a3a3a;
  text-decoration: none;
  opacity: 0.75;
}

.breadcrump-last-item {
  color: #97040c;
}

.breadcrumb-link.currentCateg {
  color: #97040c;
}

.lastItem {
  padding-right: 15px;
}

@media screen and (max-width: 768px) {
  .breadcrumb-container {
    padding-right: 0px;
  }
  .breadcrumb-item,
  .breadcrumb-item.active {
    flex: none;
  }

  .breadcrumb {
    overflow-x: auto;
    /* overflow-y: scroll; */
    width: 100%;
    flex: none;
    flex-wrap: nowrap;
  }
}

@media (max-width: 576px) {
  .breadcrumb-link {
    color: #6c6c6c;
    font-size: 11px;
  }

  .breadcrump-last-item {
    color: #6c6c6c;
  }

  .breadcrumb-link.currentCateg {
    color: #000000;
  }
}
</style>
