<template>
  <div class="art-categ">
    <div class="content-list container">
      <div class="row">
        <div
          v-for="(categ, index) in categs"
          :key="index"
          class="col-lg-3 col-md-4 col-sm-6 col-6 art-item-wrapper"
        >
          <div class="art-item prod-list">
            <div class="img-prod-art-wrapper">
              <router-link :to="{ name: 'ProductList', params: { slug: categ.slug } }">
                <img :src="categ.preview_image" />
                <!-- <img src="@/assets/images/demo_categ.png"> -->
              </router-link>
            </div>
            <div class="read-more">
              <router-link :to="{ name: 'ProductList', params: { slug: categ.slug } }">{{
                categ.name
              }}</router-link>
            </div>
            <div class="item-art-inner">
              <router-link :to="{ name: 'ProductList', params: { slug: categ.slug } }">
                <div class="item-description">
                  {{ categ.seo_params.seo_description.substring(0, 150) }}...
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="(product, index) in products"
          :key="index + product.id"
          class="col-lg-3 col-md-4 col-sm-6 col-xs-6 art-item-wrapper"
        >
          <product-categ-card v-if="isFullWidthContent(product)" :product="product" />
          <product-card v-else :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductCategCard from "@/components/product_page/ProductCategCard.vue";
import { shopMixin } from "@/mixins/shopMixin";

export default {
  props: ["categs"],
  components: {
    ProductCard,
    ProductCategCard,
  },
  computed: {
    products() {
      const response = this.$store.getters["shop/products"];
      return response.data;
    },
  },
  mixins: [shopMixin],
  // mounted() {
  //     console.log(this.categs);
  // }
};
</script>

<style scoped>
.art-categ {
  background: #fff;
}

.content-list {
  /* margin-top: 1px; */
}

.art-item-wrapper {
  margin-bottom: 15px;
}

.art-item {
  height: 390px;
  position: relative;
  color: #090909;
}

.art-categ .art-item,
.grile_view .art-item {
  height: 450px;
}

.img-prod-art-wrapper {
  max-height: 250px;
  overflow: hidden;
}

.art-item img {
  width: 100%;
}

.read-more {
  background-color: #27272a;
  text-align: left;
  /* padding-left: 15px; */
  text-overflow: ellipsis;
  color: #fff;
  /* line-height: 30px; */
  text-transform: uppercase;
  width: 100%;
  display: table;
  font-family: Raleway Semibold;
  /* overflow: hidden; */
}

.art-categ .art-item .read-more a {
  /* background-color: #f5f4f2; */
  background-color: #fff;
  color: #000;
  font-family: Montserrat SemiBold;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 20px 0;
  height: 90px;
  vertical-align: top;
  text-overflow: ellipsis;
  width: 100%;
  display: table-cell;
  text-decoration: none;
}

.art-categ .art-item .item-art-inner .item-description {
  object-fit: contain;
  font-family: Montserrat Medium;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #121111;
  padding: 0;
}

@media (max-width: 768px) {
  .art-item-wrapper {
    margin-bottom: 15px;
    position: relative;
    padding: 0 15px;
  }
}

@media (max-width: 568px) {
  .art-item-wrapper {
    width: 100%;
  }

  .art-prod .art-item,
  .art-categ .art-item {
    height: auto;
    display: inline-block;
    width: 100%;
    float: left;
    position: relative;
  }

  .art-prod .art-item,
  .art-categ .art-item {
    margin-bottom: 15px;
  }

  .img-prod-art-wrapper {
    max-height: 100%;
    position: relative;
    border-radius: 6px;
    border: solid 1px #f6f6f6;
  }

  .img-prod-art-wrapper img {
    max-height: none;
  }

  .art-categ .art-item .read-more a {
    font-size: 14px;
    height: 76px;
    font-family: Raleway Semibold;
  }

  .art-categ .art-item .item-art-inner .item-description {
    font-size: 12px;
    color: #5d5f61;
  }

  .art-item-wrapper:nth-child(odd) {
    padding-right: 7.5px;
  }

  .art-item-wrapper:nth-child(even) {
    padding-left: 7.5px;
  }
}
</style>
