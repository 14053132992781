<template>
    <div class="section filtersNavbar d-sm-block" ref="section">
        <div class="container container-mobile">
            <div class="filters filter-content filter-header">
                <div class="filter-items filter-default filters-mobile" id="filter">
                    <div>
                        <div class="row item">
                            <div class="filter-name">
                                <span class="filter-label d-none d-sm-block"
                                    >Filtreaza:</span
                                >
                                <span class="filter-label d-block d-sm-none"
                                    >Filtreaza:</span
                                >
                            </div>

                            <div
                                class="button-group js-radio-button-group"
                                data-filter-group="categ"
                            >
                                <div
                                    v-for="(categ, index) in categories"
                                    :key="categ.slug"
                                    class="filter-row"
                                >
                                    <button
                                        class="button"
                                        :data-filter="categ.id"
                                        @click="goto('categ-mobile' + categ.slug)"
                                    >
                                        <a :class="'categ' + index"
                                            >{{
                                                categ.label != null
                                                    ? categ.label
                                                    : categ.value
                                            }}
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="products-count d-block d-sm-none"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex';

export default {
    name: "GeneralFilter",
    props: ["categories"],
    emits: ["selCat", "scrollto"],

    methods: {
        goto(id) {
            document.getElementById(id).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        },

        handleScroll() {
            const sections = document.querySelectorAll(".categ");
            sections.forEach((section, index) => {
                if (
                    window.scrollY >= section.offsetTop - 375 &&
                    window.scrollY <= section.offsetTop + 375
                ) {
                    document.querySelector("a.categ" + index).classList.add("active");
                } else {
                    document.querySelector("a.categ" + index).classList.remove("active");
                }
            });
        },
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped>
#filter .item a:hover,
#filter a.mPS2id-highlight,
#filter a.active {
    background: #97040c;
    border-radius: 28px;
}

.sticky.filtersNavbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 70px;
    background-color: rgba(0, 0, 0, 1);
    z-index: 16;
}

@media (max-width: 768px) {
    .filters-mobile {
        position: absolute;
        z-index: 109;
        height: auto;
        background-color: #000;
    }
}
</style>
