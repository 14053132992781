<template>
  <div class="" id="promotii">
    <div class="content-list grile_view grile-inside grile-mobile">
      <div v-if="isLoad == false" class="list-categ-wrraper container">
        <ul class="list-unstyled flex-wrap row">
          <SkeletonProductCard v-for="n in 4" :key="n" />
        </ul>
      </div>
      <div v-if="filterValues && isLoad == true" class="list-products-wrraper">
        <!-- iterare categorii -->
        <div v-for="filter in filterValues" :key="filter.slug" class="categ">
          <div class="container">
            <div class="scrollfakeid" :id="'categ-mobile' + filter.slug"></div>
            <h2 class="categ-title promotii-categ-title">
              {{ filter.label != null ? filter.label : filter.value }}
            </h2>

            <ul class="catalog__list list-unstyled flex-wrap row">
              <template v-if="isLoad">
                <li
                  v-for="(product, index) in filterProducts(filter.value)"
                  :key="index"
                  class="catalog__item col-lg-3 col-md-4 col-sm-6 col-6"
                >
                  <product-card :product="product" />
                </li>
              </template>
              <template v-else>
                <SkeletonProductCard v-for="n in 8" :key="n" />
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/product/ProductCard.vue";
import SkeletonProductCard from "@/components/blocks/skeleton/shop/SkeletonProductCard.vue";
import { useStore } from "vuex";
import { computed, inject } from "vue";

export default {
  name: "ProductsListFilter",
  components: {
    ProductCard,
    SkeletonProductCard,
  },

  setup() {
    const { state, commit } = useStore();

    const filters = inject("filters");
    const filterCode = computed(() => {
      return filters.value.code;
    });
    const filterValues = computed(() => {
      return filters.value.values;
    });

    return {
      filterCode,
      filters,
      filterValues,
      state,
      commit,
    };
  },
  computed: {
    isLoad() {
      return this.$store.getters["shop/categoryIsLoad"];
    },

    products() {
      return this.$store.getters["shop/products"];
    },
  },
  methods: {
    filterProducts(slug) {
      let products = this.products.data;
      var filterCode = this.filterCode;
      var filtredProducts = products.filter((product) => {
        return product.property.reduce((acc, property) => {
          return acc || (property.code == filterCode && property.value == slug);
        }, false);
      });
      return filtredProducts;
    },
  },
  unmounted() {
    this.$store.dispatch("shop/actCategoryIsLoad", false);
  },
};
</script>

<style scoped>
.scrollfakeid {
  position: absolute;
  left: 0;
  padding-top: 60px;
  width: 100%;
  top: -60px;
}

.list-products-wrraper .container {
  position: relative;
}

#promotii .list-products-wrraper > div:nth-child(odd) {
  background: #fff;
  position: relative;
}

#promotii .list-products-wrraper > div:nth-child(odd):before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: -100%;
}

#promotii .list-products-wrraper > div:nth-child(odd):after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  right: -100%;
}

.catalog__list {
  margin-bottom: 0;
}

.list-categ-wrraper {
  background: #f5f4f2;
  position: relative;
  padding-top: 30px;
  padding-bottom: 60px;
}

.list-categ-wrraper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f5f4f2;
  top: 0;
  left: -100%;
}

.list-categ-wrraper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f5f4f2;
  top: 0;
  right: -100%;
}

@media (max-width: 768px) {
  .list-categ-wrraper:before,
  .list-categ-wrraper:after,
  #promotii .list-products-wrraper > div:nth-child(odd):after,
  #promotii .list-products-wrraper > div:nth-child(odd):before {
    content: none;
  }
}
</style>
