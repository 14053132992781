<template>
  <div class="results-button">
      <pf-button :class="'btn-large btn-red'">
        <div class="results-button-text">
          <div>Salveaza si vezi rezultate</div>
          <div v-if="activeFilters.length" style="font-size:12px; font-family:Montserrat Light;">{{totalProducts}} rezultate</div>
        </div>
      </pf-button>
  </div>
</template>

<script>
import PfButton from "@/components/buttons/PfButton.vue";

export default {

  components: {
    PfButton
  },
  name: 'ResultFilterMobile',
  computed: {
    totalProducts() {
      return this.$store.getters["shop/totalProducts"];
    },
    activeFilters() {
      return this.$store.getters["shop/propertyParams"];
    },
  },
  methods: {


  }
};
</script>

<style scoped>

.results-button {
    height: 88px;
    background-color: transparent;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-mobile-filter .btn-large {
  padding: 0px 20px 0 20px !important;
  height: 44px;
  line-height: 44px;
  width: 90%;
  border-radius: 30px;
  margin-bottom: 0;
  border: 0;
}

.products-mobile-filter .results-button-text div{
  font-family: 'Montserrat SemiBold';
  line-height: initial;
}

</style>