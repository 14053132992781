<template>
  <div class="item selected">
    <div class="filter-name" @click="openFilter">
      <span class="filter-label">{{ filter.name }}</span>
      <span
        class="filter-down-icon"
        :style="[windowWidth > 768 ? { backgroundImage: filterArrow } : '']"
      >
        <font-awesome-icon
          v-if="windowWidth < 768"
          :icon="{ prefix: 'fas', iconName: 'angle-right' }"
        />
      </span>
      <div v-if="windowWidth < 768 || (windowWidth >= 768 && !isFilterOpen)" class="active-filters">
        <span v-show="activeFilters.find(x => x.filter.id === filter.id) !== undefined" @click.stop="removeFilter(filter.id)" class="active-filter">
          <img src="~@/assets/images/red-x-circled.svg">
        </span>
        <template v-for="(activeFilter, index) in activeFilters" 
            :key="index + activeFilter.id">
              <span class="active-filter" v-if="filter.id == activeFilter.filter.id">
                {{activeFilter.option.value}},
              </span>
        </template>
      </div>
    </div>
    <div v-show="isFilterOpen || windowWidth < 768" class="filter-option" :style="[windowWidth < 768 ? isFilterOpen ? 'transform: translate(100%)' : 'transform: translate(200%)' : '']">
      <FilterOption
        v-for="(value, index) in filter.values"
        :key="filter.id + value.id + index"
        :id="filter.id + '_' + value.id"
        :option="value"
        :index="index"
        :filter="filter"
      />
    </div>
  </div>
</template>

<script>
import FilterOption from "@/components/product_list/FilterOption.vue";

export default {
  components: {
    FilterOption,
  },
  props: ["filter", "closeOptions"],
  watch: { 
      'closeOptions'() {
        if(this.closeOptions == false) {
          this.isFilterOpen = this.closeOptions;
        } else {
          this.filterArrow = `url(${require("@/assets/images/arrow-down.svg")}`;
        }
      },
    },
  emits: ['isFilterOpen'],
  data() {
    return {
      itemRefs: [],
      isFilterOpen: false,
      windowWidth: window.innerWidth,
      filterArrow: `url(${require("@/assets/images/arrow-down.svg")}`,
      timeout: "",
    };
  },
  computed: {
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
    filterArrowc() {
      if (this.windowWidth > 768) {
        return `url(${require("@/assets/images/arrow-down.svg")}`;
      }
      return false;
    },
    activeFilters() {
      return this.$store.getters["shop/propertyParams"];
    },
  },
  methods: {
    openFilter() {
      if (this.isFilterOpen) {
        clearTimeout(this.timeout);
      }
      this.isFilterOpen = !this.isFilterOpen;
      this.$parent.$emit('isFilterOpen', this.isFilterOpen);
      if (this.windowWidth > 768) {
        this.filterArrow = this.isFilterOpen
          ? `url(${require("@/assets/images/arrow-up.svg")}`
          : `url(${require("@/assets/images/arrow-down.svg")}`;
      }
    },
    closeFilter() {
      if (this.windowWidth > 768 && this.isFilterOpen) {
        this.timeout = setTimeout(() => {
          this.isFilterOpen = false;
          this.$parent.$emit('isFilterOpen', this.isFilterOpen);
          this.filterArrow = `url(${require("@/assets/images/arrow-down.svg")}`;
        }, 1500);
      }
    },
    removeFilter(filterId) {
      this.$store.dispatch("shop/actPage", 1);
      this.$store.dispatch("shop/changeSelFromShop", true);
      this.activeFilters.forEach(element => {
        var propertyId = element.id.split('_')[0];
        if(filterId == propertyId) {
          this.$store.dispatch("shop/removePropertyParam", element);

          const filters = this.$store.getters["shop/filters"];
          const params = [];
          filters.filter((el) => {
            let filter = el.code;
            this.activeFilters.map((active_filter) => {
              if (active_filter.filter.code === el.code) {
                filter = filter + "_" + active_filter.option.url;
              }
            });
            if (filter != el.code) {
              params.push(filter);
            }
          });

          if(this.$route.params.sortby) {
            if (params) {
              this.$router.push({
                name: "productsfiltersort",
                params: { filters: params, sortby: this.$route.params.sortby },
              });
            } else {
              this.$router.push({
                name: "productssort",
                params: { sortby: this.$route.params.sortby }, 
              });
            }
          } else if(params) {
              this.$router.push({ name: "productsfilter", params: { filters: params } });
          } else {
              this.$router.push({ name: "ProductList" });
          }

        }
      });
    }
  },
};
</script>

<style scoped>
.filter-down-icon {
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  display: table-cell;
  vertical-align: middle;
  background-position: center;
}

.filter-option {
  width: 100%;
  float: left;
  padding: 15px 0px;
  padding-top: 10px !important;
  /* position: absolute; */
  z-index: 12;
  background-color: #fff;
  top: 60px;
  height: auto;
  font-family: Montserrat Regular;
}

@media (min-width: 992px) and (max-width: 1200px) {
  span.filter-down-icon {
    right: 8px;
  }
}

/* filter-mobile */

.filter-mobile.item {
  background: #fff;
  /* border-bottom: 1px solid #c4c4c4; */
  border-bottom: solid 1px #cdcbcb;
  /* padding: 15px 5px; */
  text-align: left;
}

.filter-mobile.item .filter-name {
  background: #fff;
  color: #000;
  font-size: 24px;
}

.filter-mobile.item .filter-option {
  width: 100%;
  float: none;
  position: fixed;
  top: 0;
  transform: translate(100%, 0);
  padding: 0 !important;
}

.filter-mobile.item .filter-down-icon {
  position: absolute;
  display: block;
  line-height: 20px;
  right: 0;
  width: 48px;
  top: 35px;
  text-align: center;
  /* background: #990000; */
  /* color: #fff; */
  border-radius: 20px;
}

.filter-mobile.item .fa-caret-up:before {
  position: relative;
  top: -2px;
}

.active-filters {
  font-size: 14px;
  font-family: 'Raleway SemiBold';
  color: #7c7878;
  position: absolute;
  bottom: 10px;
  left: 50px;
  width: calc(100% - 30px);
  height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.filter-mobile .active-filters {
  left: 12px;
  bottom: 16px;
}

.active-filter {
  font-size: 14px;
  font-family: 'Raleway SemiBold';
  color: #7c7878;
  margin-right: 5px;
}

.filter-mobile .active-filter {
  margin-right: 15px;
}

.active-filter img {
  vertical-align: text-bottom;
  height: 16px;
}
</style>
