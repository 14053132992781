<template>
  <a class="close" @click="closeFilter"></a>
</template>

<style scoped>
.close {
  position: absolute;
  right: -10px;
  top: 5px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  z-index: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 3px;
  background-color: #fff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
</style>

<script>
export default {
  methods: {
    closeFilter() {
      this.$store.dispatch("filter/actionImgFilter", true);
    },
  },
};
</script>
