<template>
    <div class="art-item prod-list">
        <div class="img-prod-art-wrapper">
            <router-link :to="{ name: 'productpage', params: { slug: product.slug } }">
                <img :src="product.thumbnail" />
                <!-- <img src="@/assets/images/demo_categ.png"> -->
            </router-link>
        </div>
        <div class="read-more">
            <router-link :to="{ name: 'productpage', params: { slug: product.slug } }">{{
                product.name
            }}</router-link>
        </div>
        <div class="item-art-inner">
            <router-link :to="{ name: 'productpage', params: { slug: product.slug } }">
                <div class="item-description">
                    <p v-html="product.preview_text.substring(0, 150) + '...'"></p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: ["product"],
};
</script>

<style scoped>
.art-item .read-more a {
    background-color: #f5f4f2;
    color: #000;
    font-family: Montserrat SemiBold;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 20px 0;
    height: 90px;
    vertical-align: top;
    text-overflow: ellipsis;
    width: 100%;
    display: table-cell;
    text-decoration: none;
}

.art-item,
.grile_view .art-item {
    height: 450px;
}

.img-prod-art-wrapper {
    max-height: 250px;
    overflow: hidden;
}

.art-item img {
    width: 100%;
}

.read-more {
    background-color: #27272a;
    text-align: left;
    text-overflow: ellipsis;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    display: table;
    font-family: "Raleway Semibold";
}

.art-item .read-more a {
    background-color: #f5f4f2;
    color: #000;
    font-family: "Montserrat SemiBold";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 20px 0;
    height: 90px;
    vertical-align: top;
    text-overflow: ellipsis;
    width: 100%;
    display: table-cell;
    text-decoration: none;
}

.art-item .item-art-inner .item-description {
    -o-object-fit: contain;
    object-fit: contain;
    font-family: "Montserrat Light";
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #121111;
    padding: 0;
}
</style>
