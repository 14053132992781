<template>
    <li class="catalog__item col-lg-3 col-md-4 col-sm-6 col-6">
        
        <div class="product-entity">

            <div class="item thumb">

                    <div class="produs-image">
                        <skeleton-box height="100%" width="100%"/>
                    </div>

                    <div class="produs-item-content">
                        <div itemprop="name" class="produse-name"><skeleton-box :min-width="20" :max-width="50"/></div>

                        <div itemprop="description" class="produse-description-c">
                            <skeleton-box/>
                            <skeleton-box/>
                        </div>

                        <div class="produse-price">
                            <div>
                                <skeleton-box :min-width="20" :max-width="50"/>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </li>
</template>

<script>
import SkeletonBox from '@/components/blocks/SkeletonBox.vue';

export default {
   components: {
    SkeletonBox
  },   
}
</script>

<style scoped>

.produs-image span {
    display: block;
    margin: auto;
    position: absolute;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    max-height: 100%;
    max-width: 100%;
}

</style>