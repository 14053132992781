<template>
  <div class="filter-row">
    <input
      type="checkbox"
      :name="option.value + '_' + index"
      value="6up-to10"
      :checked="option.isChecked"
      :id="'input_' + filter.slug + '_' + option.value + '_' + index"
      @click="selectProperty(filter.id, option.value)"
    />
    <label
      class="filter-val"
      :for="'input_' + filter.slug + '_' + option.value + '_' + index"
      ><span v-html="option.value"></span>&nbsp;<span v-html="filter_measure"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["option", "index", "filter"],
  data() {
    return {
      isChecked: false,
    };
  },
  computed: {
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
    property_params() {
      return this.$store.getters["shop/propertyParams"];
    },
    filter_measure() {
      if (this.filter.measure !== null) {
        return this.filter.measure;
      } else return "";
    },
    currentPage() {
      return this.$store.getters["shop/page"];
    },
  },
  methods: {
    selectProperty(id, value) {
      // console.log(this.filter_params);
      this.$store.dispatch("shop/actPage", 1);
      this.$store.dispatch("shop/changeSelFromShop", true);

      let key = "";
      if (this.filter.filter_type === "checkbox") {
        key = "property[" + id + "][" + this.index + "]";
      }
      const param = {
        id: id + "_" + value,
        key: key,
        optionId: this.option.id,
        value: this.option.slug,
        filter: this.filter,
        option: this.option,
      };

      var sortby = this.$route.params.sortby !== undefined ? this.$route.params.sortby : '';
      if(sortby) {
        this.filter_params.sort = sortby;
      }

      // console.log("1 - params selectat", param);
      if (this.option.isChecked) {
        this.$store.dispatch("shop/removePropertyParam", param);
        // this.$store.dispatch('shop/getProducts', this.filter_params);
      } else {
        this.$store.dispatch("shop/addPropertyParam", param);
        this.$store.dispatch("shop/getProducts", this.filter_params);
      }

      const filters = this.$store.getters["shop/filters"];
      const params = [];
      filters.filter((el) => {
        let filter = el.code;
        this.property_params.map((active_filter) => {
          if (active_filter.filter.code === el.code) {
            filter = filter + "_" + active_filter.option.url;
          }
        });
        if (filter != el.code) {
          params.push(filter);
        }
      });
      // console.log(params);

      // console.log(this.property_params);
      // const params = this.property_params.map(el => {
      //     console.log(el);
      //     return el.filter.code + '_' + el.option.slug;
      // });
      // console.log(params.length);
      if (params.length) {
        if(sortby) {
          this.$router.push({ name: "productsfiltersort", params: { filters: params, sortby: sortby } });
        } else {
          this.$router.push({ name: "productsfilter", params: { filters: params } });
        }
      } else {
        if(sortby) {
          this.$router.push({ name: "productssort", params: { sortby: sortby } });
        } else {
          this.$router.push({ name: "ProductList" });
        }
      }
    },
  },

  mounted() {
    // console.log(this.filter_params);
  },
};
</script>

<style scoped>
.filter-row {
  clear: both;
  float: left;
  width: 95%;
  position: relative;
  padding: 0 0 0 50px;
  border-bottom: solid 0.5px #cdcbcb45;
}

.filter-row label.filter-val {
  font-family: Raleway SemiBold;
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
  width: 100%;
  color: #000;
  text-align: left;
  /* border-bottom: solid 1px #cdcbcb; */
  padding: 12px 0;
  margin: 0;
}

.filter-row input {
  float: right;
}

.filter-row input[type="checkbox"] {
  appearance: initial;
  -moz-appearance: initial;
  -webkit-appearance: initial;
  height: 20px;
  width: 20px;
  border: 1px solid #a9a9a9;
  background: #f4f4f4;
  border-radius: 10px;
  margin-bottom: -7px;
  outline: none;
  position: absolute;
  right: 8px;
  top: 12px;
  margin: 4px 0 0;
}

label.filter-val.active {
  color: #404040;
}

.filter-row input[type="checkbox"]:checked,
.filter-row .checked input[type="checkbox"] {
  background-color: #b60000;
}

.filter-row:hover,
.filter-row:hover label.filter-val {
  /* background-color: #9d0000; */
  /* color: #fff; */
}

.filter-mobile.item .filter-option .filter-row .filter-val {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 40px;
  cursor: pointer;
  width: 100%;
  color: #000;
  text-align: left;
  padding-left: 12px;
  padding-right: 10px;
  font-family: 'Raleway SemiBold';
}

.filter-mobile.item .filter-option input[type="checkbox"] {
  display: none;
}

.filter-mobile.item .filter-option input[type="checkbox"]:checked + label {
  background-image: url("~@/assets/images/green-check.svg");
  background-position: right 20px center;
  background-size: auto 25%;
  background-repeat: no-repeat;
}

.filter-mobile.item .filter-row:hover,
.filter-mobile.item .filter-row:hover label.filter-val {
  background-color: #fff;
}

.filter-mobile.item .filter-option .filter-row {
  border-bottom: 1px solid #cdcbcb;

}
</style>
