<template>
  <section class="container mt-4 filter-products">
    <div class="product-controls">
      <div class="sort-products">
        <button class="btn btn-filter black" type="button" id="dropdownMenuButton" ref="dropmenuButton" @click="toggleDropmenu" data-toggle="dropdown" data-flip="false" aria-haspopup="true" aria-expanded="false">
          Sortează
          <img v-if="isDropmenuOpen" src="@/assets/images/arrow-up-white.png" />
          <img v-else src="@/assets/images/arrow-down-white.png" />
        </button>
        <ul ref="dropmenu" class="dropdown-menu dropdown-menu-right sort-products-menu">
          <li v-if="!hideStock"><label class="dropdown-item"><input type="radio" name="sort1" value="in_stock|asc" v-model="sortByValue" @click="sortProducts('in_stock|asc')">În stoc</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort2" value="custom_promotion|asc" v-model="sortByValue" @click="sortProducts('custom_promotion|asc')">Oferte speciale</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort3" value="custom_popularity|desc" v-model="sortByValue" @click="sortProducts('custom_popularity|desc')">Popularitate</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort4" value="new" v-model="sortByValue" @click="sortProducts('new')">Noutăți</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort5" value="custom_price|asc" v-model="sortByValue" @click="sortProducts('custom_price|asc')">Preț crescător</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort6" value="custom_price|desc" v-model="sortByValue" @click="sortProducts('custom_price|desc')">Preț descrescător</label></li>
        </ul>
      </div>
      <button v-if="filters?.length" class="btn btn-filter" @click="openFilters = true">
        Filtrează
        <span v-if="activeFilters?.length" class="filters-applied"
          >{{ activeFilters.length }}
        </span>
        <img v-else src="@/assets/images/filter_icon.svg" />
      </button>
      <span v-if="activeFilters?.length" class="no-of-products">{{totalProducts}} de rezultate</span>
    </div>
    <VueFinalModal
      v-if="filters?.length"
      v-model="openFilters"
      :content-transition="'vfm-slide-right'"
      class="filter-modal"
      content-class="filter-modal-content"
    >
      <div class="filter-modal-header">
        <span class="main-title">
          Filtrează după:
        </span>
        <button class="btn btn-close" @click="openFilters = false">
          <img src="@/assets/images/x-close.svg" />
        </button>
      </div>
      <div class="filter-modal-body">
        <FilterCard
          v-for="(filter, index) in filters"
          :key="filter.id + index"
          :filter="filter"
          :closeOptions="closeOptions"
        />
      </div>
      <div class="filter-modal-footer">
        <button class="btn btn-filter red" @click="openFilters = false">Salveaza si vezi rezultate <span v-if="activeFilters.length">{{totalProducts}}</span></button>
        <button class="btn btn-filter remove" :class="activeFilters.length ? 'white' : 'grey'" @click="resetAllFilter" >Sterge tot</button>
      </div>
    </VueFinalModal>
  </section>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import FilterCard from "@/components/product_list/FilterCard.vue";
// import ActiveFilterBox from "@/components/product_list/ActiveFilterBox.vue";
// import ResetFilterBox from "@/components/filter/products/ResetFilterBox.vue";
// import SkeletonFilterCard from "@/components/blocks/skeleton/shop/SkeletonFilterCard.vue";

export default {
  components: {
    FilterCard,
    // ActiveFilterBox,
    // ResetFilterBox,
    // SkeletonFilterCard,
    VueFinalModal,
  },
  props: ["totalProducts"],
  data() {
    return {
      openFilters: false,
      isDropmenuOpen: false,
      sortByValue: this.$route.params.sortby || '',
      closeOptions: '',
    };
  },
  watch: {
    'openFilters'() {
      this.closeOptions = this.openFilters;
    }
  },
  computed: {
    filters() {
      const filters = this.$store.getters["shop/filters"].filter((filter) => {
        return filter.filter_type !== "list";
      });

      return filters;
    },
    activeFilters() {
      return this.$store.getters["shop/propertyParams"];
    },
    isCategLoad() {
      return this.$store.getters["shop/categoryIsLoad"];
    },
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
    currentPage() {
      return this.$store.getters["shop/page"];
    },
    categ() {
      return this.$store.getters["shop/category"];
    },
    hideStock() {
      if (this.isCategLoad && this.categ.labels) {
          var categHideStock = this.categ.labels.find((elem) => {
          return elem.code === "hide-stock";
        });
      }
      if(categHideStock) {
        return 1;
      } else {
        return 0;
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.sortByValue = to.params.sortby || '';
    next();
  },
  methods: {
    resetAllFilter() {
      this.$store.dispatch("shop/resetAllFilters");
      this.$store.dispatch("shop/actPage", 1);
      this.$store.dispatch("shop/changeSelFromShop", true);
      if(this.sortByValue) {
        this.$router.push({ name: "productssort", params: { sortby: this.sortByValue } });
      } else {
        this.$router.push({ name: "ProductList" });
      }
      this.openFilters = false;
    },
    sortProducts(sort_by) {
      this.isDropmenuOpen = false;
      // Daca e selectata aceeasi optiune de sortare a doua oara, resetam sortarea 
      var resetSorting = 0;
      if(this.sortByValue == sort_by) {
        this.filter_params.sort = this.categ.sort_type;
        this.sortByValue = '';
        resetSorting = 1;
      } else {
        this.sortByValue = sort_by;
        this.filter_params.sort = sort_by;
      }
      this.$store.dispatch("shop/actionSpinner", true);
      this.$store
        .dispatch("shop/getProducts", this.filter_params)
        .then(() => {
          if (resetSorting) {
            if(this.$route.params.filters) {
              this.$router.push({ name: "productsfilter", params: { filters: this.$route.params.filters } });
            } else {
              this.$router.push({ name: "ProductList" });
            }
          } else if (this.$route.params.filters) {
            this.$router.push({
              name: "productsfiltersort",
              params: { filters: this.$route.params.filters, sortby: sort_by },
            });
          } else {
            this.$router.push({ 
              name: "productssort",
              params: { sortby: sort_by }, 
            });
          }
        })
        .then(() => {
          this.$store.dispatch("shop/actionSpinner", false);
        });
    },
    toggleDropmenu() {
      this.isDropmenuOpen = !this.isDropmenuOpen;
    },
    closeDropmenu(event) {
      if (!this.$refs.dropmenu.contains(event.target) && !this.$refs.dropmenuButton.contains(event.target)) {
        this.isDropmenuOpen = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.closeDropmenu);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeDropmenu);
  },
}
</script>

<style scoped>
@media (max-width: 768px) {
  .filter-products {
    display: none;
  }
}

.wrp-pag-reset > div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.product-controls .btn-filter {
  padding: 8px 5px;
  width: 150px;
}

.product-controls .btn-filter img {
  margin-left: 10px;
}

.filters-applied {
  color: #000000;
  background: #ffffff;
  border-radius: 50%;
  padding: 1px 7px;
  font-size: 12px;
  margin-left: 7px;
  vertical-align: text-bottom;
}

.btn-close {
  padding: 0;
  vertical-align: text-bottom;
  float: right;
}

.btn-filter {
  background-color: #97040c;
  color: #fff;
  border-radius: 22px;
  font-family: Montserrat SemiBold;
  font-size: 17px;
  padding: 8px 20px;
}

.btn-filter img {
  width: 20px;
  padding-bottom: 4px;
  padding-right: 6px;
}

.btn-filter.red, .btn-filter.remove {
  font-size: 13px;
  padding: 10px 30px;
  border-radius: 30px;
}

.btn-filter.red {
  background-color: #97040c;
  margin-right: 15px;
}

.btn-filter.black {
  background-color: #000;
}

.btn-filter.grey {
  background-color: #dfdfdf;
  color: #929292;
}

.btn-filter.white {
  background-color: #fff;
  color: #000;
  border: solid 1.2px #000;
}

.product-controls {
  text-align: right;
}

.product-controls .sort-products {
  display: inline-block;
}

.product-controls .btn-filter {
  margin-left: 22px;
}

.no-of-products {
  font-family: Montserrat Medium;
  color: #747171;
  margin-left: 40px;
}

.sort-products-menu {
  width: 370px;
  /* height: 370px; */
  margin-top: 10px;
  border-radius: 20px;
  border: solid 1.5px #e5e5e5;
  box-shadow: 0 0 6px 6px rgba(235, 232, 232, 0.5);
  padding: 10px;
  z-index: 5;
}

.sort-products-menu li label {
  font-family: Raleway SemiBold;
  font-size: 18px;
  color: #000;
  padding: 15px 30px;
  margin-bottom: 0;
  background-color: #fff;
}

.sort-products-menu li:not(:last-child) label {   
  border-bottom: solid 1px #cdcbcb45;
}

.sort-products-menu li label:hover, .sort-products-menu input[type="radio"]:hover {
  cursor: pointer;
}

.sort-products-menu input[type="radio"] {
  appearance: initial;
  -moz-appearance: initial;
  -webkit-appearance: initial;
  height: 20px;
  width: 20px;
  border: 1px solid #a9a9a9;
  background: #f4f4f4;
  border-radius: 10px;
  outline: none;
  vertical-align: text-top;
  margin-right: 15px;
}

.sort-products-menu input[type="radio"]:checked,
.sort-products-menu .checked input[type="radio"] {
  background-color: #b60000;
}

</style>

<style>
.filter-modal .filter-modal-content {
  border-radius: 33px 0 0 33px;
  /* padding: 50px 30px 50px 50px; */
  background: #fff;
  width: 470px;
  height: 100%;
  float: right;
  position: relative;
}

.filter-modal .filter-modal-header {
  border-bottom: solid 0.5px #8a8a8a;
  padding: 40px 40px 30px 40px;
}

.filter-modal .main-title {
  font-family: Raleway ExtraBold;
  font-size: 18px;
}

.filter-modal-body {
  height: calc(100% - 165px);
  overflow-y: scroll;
}

.filter-modal-body::-webkit-scrollbar {
  width: 17px;
}

.filter-modal-body::-webkit-scrollbar-track {
  background-color: #fff;
}

.filter-modal-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #d8d8d8;
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.filter-modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 5px;
  background-color: #fff;
  border-radius: 33px;
}

.filter-modal-footer::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgba(255,255,255,0.8), rgba(255,255,255,0.2));
  width: 100%;
  height: 25px;
  left: 0;
  top: -20px;
}

</style>
