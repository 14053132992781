<template>
  <div class="selected-filter-values">
    <div class="filter-header">
      <span v-if="!isFilterOpen">Filtrează după:</span>
      <span v-else @click="closeOptions">
        <font-awesome-icon icon="arrow-left" size="2x" style="vertical-align: middle;" />
      </span>
    </div>
    <div class="close-filter" @click="closeFilter">
      <font-awesome-icon v-if="!isFilterOpen" icon="times" size="2x" />
      <span v-else class="close-ok">OK</span>
    </div>
  </div>
</template>

<script>

export default {
  props: ["isFilterOpen"],
  emits: ["closeFilter", "closeOptions"],
  name: "ActiveFilterMobile",
  computed: {
    activeFilters() {
      // console.log(this.$store.getters["shop/propertyParams"]);
      return this.$store.getters["shop/propertyParams"];
    },
  },
  methods: {
    closeFilter() {
      this.$emit("closeFilter");
      this.$emit("closeOptions");
    },
    closeOptions() {
      this.$emit("closeOptions");
    }
  },
};
</script>

<style scoped>
.selected-filter-values {
  /* order: 1; */
  background-color: #fff;
  /* margin-bottom: 5px; */
  /* display: flex; */
  width: 100%;
  /* overflow-y: scroll; */
  /* overflow-x: auto; */
  min-height: 60px;
  /* align-items: center; */
  /* position: fixed; */
  z-index: 20;
  border-bottom: 1px solid #cdcbcb;
  /* border-color: rgba(0, 0, 0, 0.1); */
}

.reset-section {
  overflow-x: auto;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  height: 60px;
  width: calc(100% - 60px);
  padding-left: 15px;
}

.close-filter {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  z-index: 30;
  border-top: 0;
  text-align: center;
  cursor: pointer;
  top: 0;
}

.close-filter svg {
  margin-top: 15px;
  color: #000;
}

.filter-header {
  font-family: 'Raleway SemiBold';
  font-size: 14px;
  line-height: 60px;
  padding-left: 12px;
}

.close-ok {
  font-family: 'Raleway Bold';
  font-size: 22px;
  line-height: 60px;
}
</style>
