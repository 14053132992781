<template>
  <div id="infscr-loading" :class="{ full: isNotFullPage }">
    <img alt="Loading..." src="@/assets/images/logo_loader.svg" />
    <!-- <div v-if="spinner">
      <slot name="default"></slot>
    </div> -->
    <!-- <div v-else>
      <slot name="noitems"></slot>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    spinner: {
      type: Boolean,
      default: false,
    },
    isNotFullPage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
/* Infinite Scroll loader */
#infscr-loading {
  text-align: center;
  z-index: 100;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 82px;
  height: 82px;
  padding: 12px;
  background-repeat: no-repeat;
  background-size: 82px;
  background-position: center;
  background-color: #97040c;
  /* opacity: 0.85; */
  color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border: solid 4px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

#infscr-loading.full {
  position: relative;
  transform: none;
  left: 0;
}

div#infscr-loading img {
  /* width: 82px; */
  /* height: 82px; */
  /* padding: 15px; */
  /* margin: 23px 0px; */
  display: inline-block !important;
  /* border: 4px solid rgba(255, 255, 255, 0.5); */
  /* border-radius: 50%; */
  /* background-color: #97040c; */
}

div#infscr-loading div {
  font-size: 15px;
  line-height: 15px;
  position: absolute;
  bottom: 5px;
  width: 90%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

div#infscr-loading:before {
  border-top-width: 2px;
  margin-top: 0px;
  height: 50px;
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  /* margin-top: -3px; */
  margin-left: 0px;
  width: 41px;
  height: 41px;
  border-top: solid 4px #fff;
  border-right: solid 4px #fff;
  border-top-right-radius: 100%;
  transform-origin: left bottom;
  -webkit-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
  z-index: 999;
  /* border-color: #fff; */
}
</style>
