<template>
      <section class="filter-products flex-grow-1">

        <div class="filter-items" :style="[!closeOptions ? 'transform: translate(0)' : 'transform: translate(-100%)']">
            <FilterCard v-for="(filter, index) in filters" :key="filter.id + index" :filter="filter" :closeOptions="closeOptions" :class="'filter-mobile'"/>
        </div>

    </section>
</template>

<script>
import FilterCard from "@/components/product_list/FilterCard.vue";

export default {
    props: ['closeOptions'],
    components: {
        FilterCard,
    }, 
    computed: {
        filters() {
            return this.$store.getters['shop/filters'];
        },
        activeFilters() {
            return this.$store.getters['shop/propertyParams'];
        },
    },
    methods: {
        resetAllFilter() {
            // console.log('reset');
            this.$store.dispatch('shop/resetAllFilters');
        }
    }
}
</script>

<style scoped>

.filter-products {
    overflow-x:hidden;
    overflow-y:scroll;
}

.filter-items {
    transition: all 0.3s ease;
}


</style>