<template>
  <section class="catalog container mt-4">
    <div class="_ajax_catalog_wrapper">
      <div class="top-mobile">
        <pagination
          v-if="lastPage > 1 && $route.params.page !== 'all'"
          v-model="page"
          :records="total"
          :per-page="perpage"
          :options="paginationOptions"
        />
      </div>

      <ul
        v-if="$route.params.page !== 'all'"
        class="catalog__list list-unstyled flex-wrap row"
      >
        <template v-if="isCategLoad">
          <li
            v-for="(product, index) in products"
            :key="index"
            class="catalog__item col-lg-3 col-md-4 col-sm-6 col-6"
          >
            <product-categ-card v-if="isFullWidthContent(product)" :product="product" />
            <product-card v-else :product="product" />
          </li>
        </template>
        <template v-else>
          <SkeletonProductCard v-for="n in 8" :key="n" />
        </template>
      </ul>

      <products-all v-else :allProducts="products"></products-all>

      <div
        v-if="isCategLoad && products?.length === 0 && $route.params.page !== 'all'"
        class="pf-no-products"
        style="padding-bottom: 20px"
      >
        <p>Nu exista nici un produs care sa contina criteriile selectate.</p>
        <p>Va rog schimbati criterile de filtrare!</p>
      </div>

      <!-- <pagination /> -->
      <div class="bottom-pagination">
        <pagination
          v-if="lastPage > 1 && $route.params.page !== 'all'"
          v-model="page"
          :records="total"
          :per-page="perpage"
          :options="paginationOptions"
          @paginate="myCallback"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { shallowRef } from "vue";

import ProductCard from "@/components/product/ProductCard.vue";
import ProductCategCard from "@/components/product_page/ProductCategCard.vue";
import CustomPagination from "@/components/pagination/CustomPagination.vue";
import Pagination from "v-pagination-3";
import { shopMixin } from "@/mixins/shopMixin";
import SkeletonProductCard from "@/components/blocks/skeleton/shop/SkeletonProductCard.vue";
import ProductsAll from "@/components/product_list/ProductsAll.vue";

export default {
  name: "ProductGrid",
  components: {
    ProductCard,
    ProductCategCard,
    Pagination,
    SkeletonProductCard,
    ProductsAll,
  },
  data() {
    return {
      page: 1,
      isLoading: false,
    };
  },
  watch: {
    currentPage() {
      // console.log("pagina curenta", this.currentPage);
      this.page = this.currentPage;
    },
  },
  computed: {
    products() {
      const response = this.$store.getters["shop/products"];
      return response.data;
    },

    currentPage() {
      return this.$store.getters["shop/page"];
    },

    perpage() {
      const response = this.$store.getters["shop/products"];
      return +response.meta?.per_page;
    },

    total() {
      // console.log(this.$store.getters['shop/totalProducts']);
      return +this.$store.getters["shop/totalProducts"];
    },
    lastPage() {
      const response = this.$store.getters["shop/products"];
      return +response.meta?.last_page;
    },
    pageAll() {
      return this.$store.getters["shop/pageAll"];
    },
    updated_page() {
      return this.$store.getters["shop/page"];
    },

    paginationOptions() {
      return {
        chunk: 3,
        chunksNavigation: "scroll",
        edgeNavigation: true,
        texts: {
          count: "{page} din {pages}",
        },
        theme: "bootstrap4",
        template: shallowRef(CustomPagination),
      };
    },
    spinner() {
      return this.$store.getters["shop/spinner"];
    },
    isCategLoad() {
      return this.$store.getters["shop/categoryIsLoad"];
    },
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
  },
  mixins: [shopMixin],
  methods: {
    async myCallback(event) {
      this.$store.dispatch("shop/changeSelFromShop", true);
      // console.log("click");
      if (this.currentPage !== this.page) {
        this.$store.dispatch("shop/actPage", event).then(() => {
          this.getData(this.currentPage);
        });
      }
    },

    async getData(pageNumber) {
      this.$store.dispatch("shop/actionSpinner", true);

      var anchor = document.querySelector("#app");
      anchor.scrollIntoView({
        behavior: "smooth",
      });

      if(this.$route.params.sort) {
        this.filter_params.sort = this.$route.params.sortby;
      }
  
      this.$store
        .dispatch("shop/getProducts", this.filter_params)
        .then(() => {
          if (this.$route.params.filters && this.$route.params.slug) {
            if(this.$route.params.sortby) {
              this.$router.push({ name: "productsfiltersortpage", params: { page: pageNumber, filters: this.$route.params.filters, sortby: this.$route.params.sortby },});
            } else {
              this.$router.push({ name: "productsfilterpage", params: { page: pageNumber, filters: this.$route.params.filters },});
            }
          } else if (this.$route.params.filters) {
            if(this.$route.params.sortby) {
              this.$router.push({ name: "productsfiltersort", params: { filters: this.$route.params.filters, sortby: this.$route.params.sortby }, });
            } else {
              this.$router.push({ name: "productsfilter", params: { filters: this.$route.params.filters, }, });
            }
          } else {
            if(this.$route.params.sortby) {
              this.$router.push({ name: "productssortpage", params: { page: pageNumber, sortby: this.$route.params.sortby }, });
            } else {
              this.$router.push({ name: "productspage", params: { page: pageNumber }, });
            }
          }
        })
        .then(() => {
          this.$store.dispatch("shop/actionSpinner", false);
        });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

  },

  created() {
    if (this.$route.params.page && !isNaN(this.$route.params.page)) {
      // console.log(this.$route.params.page);

      this.page = +this.$route.params.page;
    }
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media (max-width: 576px) {
  .catalog__item:nth-child(odd) {
    padding-right: 7.5px;
  }

  .catalog__item:nth-child(even) {
    padding-left: 7.5px;
  }
}
</style>
