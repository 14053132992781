<template>
  <ul class="catalog__list list-unstyled flex-wrap row">
    <template v-if="isCategLoad">
      <li
        v-for="(product, index) in products"
        :key="index"
        class="catalog__item col-lg-3 col-md-4 col-sm-6 col-6"
      >
        <product-categ-card v-if="isFullWidthContent(product)" :product="product" />
        <product-card v-else :product="product" />
      </li>
      <!-- <div class="col-12 vld-parent" v-if="isLoading">
        <loading :active="isLoading" :is-full-page="false"></loading>
      </div> -->
    </template>

    <template v-else>
      <SkeletonProductCard v-for="n in 8" :key="n" />
    </template>
  </ul>
</template>

<script>
import ProductCard from "@/components/product/ProductCard.vue";
import { shopMixin } from "@/mixins/shopMixin";
import SkeletonProductCard from "@/components/blocks/skeleton/shop/SkeletonProductCard.vue";

// Import component
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  props: ["allProducts"],
  components: {
    ProductCard,
    SkeletonProductCard,
    // Loading,
  },
  data() {
    return {
      products: [],
      currentPage: 0,
      totalPages: "",
      showSpinner: true,
      isLoading: false,
    };
  },
  watch: {
    isCategLoad: async function () {
      this.initProducts();
    },
  },
  computed: {
    hasFetchedAllData() {
      return this.currentPage > this.totalPages;
    },
    isCategLoad() {
      return this.$store.getters["shop/categoryIsLoad"];
    },
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
    totalProducts() {
      return this.$store.getters["shop/totalProducts"];
    },
  },
  mixins: [shopMixin],
  methods: {
    async handleScroll() {
      // if (
      // window.innerHeight + window.scrollY >=
      // document.body.offsetHeight - document.body.offsetHeight / 4
      // ) {
      if (this.hasFetchedAllData || this.isLoading) {
        return;
      }

      await this.fetchProducts();
      // setTimeout(() => (this.isLoading = false), 3000);
      // }
    },
    async fetchProducts() {
      try {
        this.isLoading = true;
        this.currentPage++;

        const page_param = {
          key: "page",
          value: this.currentPage,
        };

        this.$store.dispatch("shop/addFilterParam", page_param).then(() => {
          // console.log(this.filter_params);
          this.$store
            .dispatch("shop/getProducts", this.filter_params)
            .then(() => {
              Array.prototype.push.apply(this.products, this.allProducts);
            })
            .then(() => {
              this.isLoading = false;
              if (this.hasFetchedAllData) {
                return;
              }
              this.fetchProducts();
            });
        });
      } catch (err) {
        console.log(err); // state-of-the-art-error-handling
      }
    },
    initProducts() {
      this.fetchProducts();
      this.totalPages = Math.floor(this.totalProducts / 20);
    },
  },
  created() {
    console.log("filter params in page all", this.filter_params);
    if (this.isCategLoad) {
      this.initProducts();
    }
  },
  mounted() {
    // console.log(this.currentPage);
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
