<template>
  <!-- <button class="btn btn-primary" @click="test">Test</button> -->
  <div v-if="isCategLoad" v-html="description" class="categ-content container"></div>
  <div v-else class="categ-content container">
    <skeleton-box />
    <skeleton-box />
    <skeleton-box />
    <skeleton-box />
  </div>
</template>
<script>
import fitvids from "fitvids";
import SkeletonBox from "@/components/blocks/SkeletonBox.vue";
import runOwlCarousel from "@/_helpers/owl-run.js";
export default {
  props: ["description"],
  computed: {
    isCategLoad() {
      return this.$store.getters["shop/categoryIsLoad"];
    },
  },
  methods: {
    fitvideos() {
      this.$nextTick(function () {
        var b = document.querySelectorAll("iframe");
        if (b) {
          b.forEach(function (element) {
            element.setAttribute("allowFullScreen", "1");
          });
        }
        fitvids();
      });
    },
  },
  components: {
    SkeletonBox,
  },
  mounted() {
    this.fitvideos();
    runOwlCarousel();
  },
  updated() {
    this.fitvideos();
  },
};
</script>

<style scoped>
.categ-content {
  /* font-family: Montserrat; */
  font-weight: 300;
  padding-bottom: 30px;
  padding-top: 30px;
}

.categ-content >>> strong {
  font-weight: bold;
}

.categ-content >>> h3 {
  color: #000;
  font-size: 48px;
  font-family: Raleway Bold;
  padding: 80px 0;
  width: 70%;
  margin: 0 auto;
}

.categ-content >>> .carousel-section {
  padding: 20px 0;
  clear: both;
}

.categ-content >>> .carousel-section .descrip > p {
  object-fit: contain;
  font-family: Montserrat Light;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
}

.categ-content >>> .carousel-section .header .title {
  object-fit: contain;
  font-family: Raleway SemiBold;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #000000;
}

.categ-content >>> .advantage-section-title {
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.categ-content >>> .advantage-section-subtitle {
  text-align: center;
  margin-bottom: 40px;
}

.categ-content >>> .advantage-section-subtitle p {
  line-height: 0.8;
  font-size: 14px;
  margin: 0 0 10px;
}

.categ-content >>> .advantage-icon {
  margin-bottom: 10%;
}

.categ-content >>> .advantage-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
}

.categ-content >>> .advantage-desc {
  margin-bottom: 2%;
  min-height: 250px;
  /* font-family: Montserrat; */
  font-weight: 300;
  font-size: 14px;
  color: #111;
}

@media screen and (max-width: 991px) {
  .categ-content >>> .tablet {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .categ-content >>> .carousel-section .slider-wrapper {
    width: 100vw;
  }
  .arrows .cell {
    padding-top: 35px;
    padding-bottom: 20px;
  }

  .categ-content >>> .align-carousel-right .grid-container .grid-x .cell.right-col {
    order: 1;
  }
}

@media screen and (max-width: 768px) {
  .categ-content >>> h3 {
    width: 100%;
    line-height: 24px;
    padding: 10px 0;
    font-size: 24px;
  }

  .categ-content >>> .carousel-section .header .title {
    padding-bottom: 10px;
    padding-top: 0;
    font-size: 20px;
  }

  .categ-content >>> .advantage-section-title {
    font-size: 30px;
  }

  .categ-content >>> p img {
    margin: 0 !important;
    max-width: 100% !important;
    height: auto !important;
    float: none !important;
  }
}

.carousel-section {
  padding: 20px 0;
}

.carousel-section .header .title {
  object-fit: contain;
  font-family: Raleway SemiBold;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #000000;
}

.carousel-section .descrip > p {
  object-fit: contain;
  font-family: Montserrat Light;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
}

.carousel-section .descrip > p > a {
  color: #000000;
}

.carousel-section .descrip > p > a {
  color: #000000;
}

@media screen and (min-width: 80em) {
  .carousel-section .slider-wrapper {
    /*padding-left:180px*/
  }
}
@media screen and (min-width: 90em) {
  .carousel-section .slider-wrapper {
    /*padding-left:160px*/
  }
}
.carousel-section .slide {
  width: 34vw;
  /* padding-bottom:30px; */
  position: relative;
}
@media screen and (min-width: 64em) {
  .carousel-section .slide {
    width: 30vw;
  }
}
@media screen and (min-width: 80em) {
  .carousel-section .slide {
    width: 35vw;
  }
}

@media screen and (min-width: 992px) {
  .carousel-section .slider-wrapper {
    width: 100vw;
  }
}
</style>
